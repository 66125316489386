<template>
    <div>
        <PageHeader title="Mega Arquivos">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <div class="grid grid-cols-5 mb-6 gap-x-4 card p-12" v-if="!loadingEntireScreen">
            <div class="col-span-2">
                <BaseInput label="Filtrar por descrição" v-model="filter.description" v-on:keyup.enter="loadData" />
            </div>
        </div>
        
        <div class="flex justify-center mb-4">
            <Loading text="Buscando arquivos... ..." :show="loading" />
        </div>

        <Alert text="Não existem arquivos para esse filtro" :show="!files.length && !loading && !loadingEntireScreen" />

        <table class="table min-w-full w-full" v-if="files.length && !loading && !loadingEntireScreen">
            <thead>
                <tr>
                    <th></th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                    <th>Tamanho</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-row" v-for="file in files" :key="file.id">
                    <td>
                        <BaseButton skin="primary-outline-small" :external="true" :href="file.url">Ver</BaseButton>
                    </td>
                    <td>
                        <span class="text-sm leading-5 text-blue-900" v-if="!file.edit">{{ file.description.substring(0, 70) }}</span>
                    </td>
                    <td>
                        <span class="text-sm leading-5 text-blue-900">{{ getExtension(file.file.description).substring(0, 5) }}</span>
                    </td>
                    <td>
                        <span class="text-sm leading-5 text-blue-900">{{ humanFileSize(file.file.size) }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <Pagination v-show="files.length && !loading && !loadingEntireScreen" :total="totalFiles" :limit="limit" @changePage="changePagination" />
    </div>
</template>

<script>

import { ref } from 'vue';
import PageHeader from '@/components/PageHeader';
import { MegaFilesService } from '../services/Entity';
import Alert from '@/components/Alert';
import Swal from 'sweetalert';
import Pagination from '@/components/Pagination';

export default {
    components: {
        PageHeader,
        Pagination,
        Alert,
    },
    setup() {
        const totalFiles = ref(0);
        const page = ref(1);
        const limit = ref(20);
        const loadingEntireScreen = ref(false);
        const files = ref([]);
        const loading = ref(false);
        const filter = ref({
            company: null
        });
        
        async function init() {
            loadingEntireScreen.value = true;
            await loadData();
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadFiles(filter.value);
            loading.value = false;
        }

        async function loadFiles() {
            try {
                loading.value = true;
                const { data: { total, items } } = await MegaFilesService.list(page.value, limit.value, filter.value);

                files.value = items;
                totalFiles.value = total;
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos mega arquivos',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadFiles(page.value, limit.value, filter.value);
            loading.value = false;
        }

        function getExtension(filename) {
            return filename.split('.').pop();
        }

        function humanFileSize(size) {
            let i = Math.floor( Math.log(size) / Math.log(1024) );
            return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
        }

        init();
        
        return {
            totalFiles,
            humanFileSize,
            getExtension,
            filter,
            loadData,
            changePagination,
            files,
            loading,
            page,
            limit,
            loadingEntireScreen
        };
    },
}
</script>