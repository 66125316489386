
import HttpClient from '../HttpService';

function getById(candidateId) {
    return HttpClient.get(`/candidates/${candidateId}`);
}

function create(data) {
    return HttpClient.post(`/candidates`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/candidates/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/candidates/${data.id}`, data);
}

export default {
    list,
    create,
    update,
    getById
}
