<template>
    <div>
        <h2 class="font-semibold text-md text-gray-600">{{ title }}</h2>
        <p class="font-light text-sm text-gray-600">{{ subtitle }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String
    }
}
</script>
