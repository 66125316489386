
import HttpClient from '../HttpService';

function toggleStatus(userId) {
    return HttpClient.put(`/users/${userId}/toggle/status`);
}

function getById(userId) {
    return HttpClient.get(`/users/${userId}`);
}

function create(data) {
    return HttpClient.post(`/users/`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/users/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/users/${data.id}`, data);
}

function resetPassword(data) {
    return HttpClient.put(`/users/${data.id}/reset/password`, data);
}

export default {
    list,
    create,
    update,
    resetPassword,
    toggleStatus,
    getById
}
