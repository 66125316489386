<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <div class="flex justify-center">
            <Loading text="Buscando solicitações... ..." :show="loading" />
        </div>

        <div v-if="!loading">
            <form @submit.prevent="submit" novalidate>
                <BaseFormContainer title="Nova solicitação">
                    <template v-slot:form>
                        <div class="grid grid-cols-1">
                            <div>
                                <BaseSelect :disableEmptyOption="true" is-invalid="v$.type.$error" @blur="v$.type.$touch" :options="typeOptions" label="Tipo" v-model="request.type"/>
                                <div v-for="error in v$.$errors" :key="error.$uid">
                                    <BaseErrorMessage v-if="error.$property === 'type'" :message="error.$message" />
                                </div>
                            </div>
                            <div class="mt-4">
                                <BaseSelect :disableEmptyOption="true" is-invalid="v$.admin.$error" :disabled="isEdit" @blur="v$.admin.$touch" :options="adminOptions" label="Responsável pela solicitação" v-model="request.admin" />
                                <div v-for="error in v$.$errors" :key="error.$uid">
                                    <BaseErrorMessage v-if="error.$property === 'admin'" :message="error.$message" />
                                </div>
                            </div>
                            <div class="mt-4">
                                <div>
                                    <BaseTextarea is-invalid="v$.description.$error" @blur="v$.description.$touch" rows="7" label="Descrição da solicitação" v-model="request.description" :maxlength="20000" />
                                </div>
                                <div v-for="error in v$.$errors" :key="error.$uid">
                                    <BaseErrorMessage v-if="error.$property === 'description'" :message="error.$message" />
                                </div>
                            </div>
                        </div>
                        <FileUploader class="mt-4" text="Adicionar Arquivos" :multiple="true" :showProgress="true" accept="*" @onUpload="onUpload" />
                        <FileTable class="mt-2" :loading="loadingFiles" :files="request.files" @removeFile="removeRequestFile" />
                    </template>
                    <template v-slot:actions>
                        <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                    </template>
                </BaseFormContainer>
            </form>
        </div>

    </div>
</template>

<script>

import { ref, computed, watch } from 'vue';
import { RequestService, UserAdminService } from '../services/Entity';
import Swal from 'sweetalert';
import { useRoute, useRouter } from 'vue-router';
import FileTable from '@/components/FileTable';
import FileUploader from '@/components/FileUploader';
import { useVuelidate } from '@vuelidate/core';
import { requiredMessage } from '../services/VuelidateMessageService';

export default {
    components: {
        FileTable,
        FileUploader,
    },
    setup() {
        const pageTitle = ref('Nova Solicitação');
        const loadingFiles = ref(false);
        const isEdit = ref(false);
        const loading = ref(false);
        const route = useRoute();
        const router = useRouter();
        const adminOptions = ref();
        const typeOptions = ref([
            { title: 'Reunião', value: 'meeting' },
            { title: 'Outros Assuntos', value: 'other' }
        ]);
        const request = ref({
            files: [],
            type: 'other'
        });
        const rules = computed(() => ({
            type: { required: requiredMessage },
            description: { required: requiredMessage },
            admin: { required: requiredMessage },
        }));

        async function init() {
            loading.value = true;
            await loadData()
            loading.value = false;
        }

        async function loadData() {
            const { adminId, requestId } = handleRoute(route);

            if (adminId) {
                request.value.admin = adminId;
            }

            await loadAdmins();

            if (requestId) {
                isEdit.value = true;
                await getRequestById(requestId);
            }
        }

        function handleRoute(route) {
            if (!route.params.id_admin) {
                router.push({ name: 'requests' });
                return;
            }

            return {
                adminId: route.params.id_admin,
                requestId: route.params.id_request
            };
        }

        async function loadAdmins() {
            try {
                const listingFilter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit);

                adminOptions.value = items.map(admin => {
                    return {
                        title: admin.name,
                        value: admin.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function getRequestById(requestId) {
            try {
                const { data } = await RequestService.getById(requestId);
                request.value = data;
                request.value.admin = data.admin.id;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados das solicitações',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function save() {
            try {
                const requestObject  = { ...request.value };
                loading.value = true;
                const { data } = await RequestService.create({ files: requestObject.files, type: requestObject.type, description: requestObject.description, admin: requestObject.admin });

                Swal({
                    title: 'Solicitação Salva!',
                    icon: 'success'
                });
                
                router.push({ name: 'requestform', params: { id_admin: requestObject.admin, id_request: data.id } });

            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o status da solicitação',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            try {
                const requestObject  = { ...request.value };
                loading.value = true;
                const { data } = await RequestService.update({ files: requestObject.files, id: requestObject.id, status: requestObject.status, description: requestObject.description, type: requestObject.type });

                Swal({
                    title: 'Atualizado!',
                    icon: 'success'
                });

                request.value = data;
                request.value.admin = data.admin.id;
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o status da solicitação',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            request.value.files.push(file);
        }

        async function removeRequestFile(fileId) {
            try {
                Swal({
                title: 'Deseja realmente remover o arquivo?',
                text: 'Não será possível restaurar o mesmo',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Deletar',
                        className: 'swal-button--danger'
                    }
                }}).then(async (confirm) => {
                    if (!confirm) return;

                    const requestData = { request: request.value.id, file: fileId };
                    loadingFiles.value = true;
                    request.value.files.forEach((file, index) => {
                        if (file.id == fileId) {
                            request.value.files.splice(index, 1);
                        }
                    });

                    if (!request.value.id) {
                        loadingFiles.value = false;
                        return;
                    }

                    await RequestService.removeFile(requestData.request, requestData.file, requestData);
                    loadingFiles.value = false;
                });
            } catch(err) {
                loadingFiles.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao excluir o arquivo',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'requestform' && (from != to)) {
                await init();
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, request);

        return {
            isEdit,
            removeRequestFile,
            submit,
            rules,
            typeOptions,
            v$,
            adminOptions,
            loadingFiles,
            onUpload,
            update,
            getRequestById,
            loadData,
            pageTitle,
            request,
            loading
        };
    },
}
</script>