<template>
    <div class="grid grid-cols-2 -mt-10 -ml-10 overflow-y-hidden">
        <div>
            <img src="../assets/login-background.jpg" class="w-full h-full object-cover">
        </div>
        <div class="min-h-screen flex flex-col">
            <div class="container mx-auto p-2 mt-8">
                <div class="max-w-sm w-full mx-auto px-5 py-10">                
                    <div class="flex justify-center">
                        <img src="../assets/logo-slim.png" class="w-20">
                    </div>
                    <h2 class="text-center mt-3 font-bold text-xl text-gray-600">É hora da sua empresa ir além. Nós estamos prontos para começar! </h2>
                    
                    <form @submit.prevent="onSubmit" class="py-12">
                        <BaseInput class="mt-8" type="text" placeholder="usuario" v-model="username" maxlength="50" @keyup.enter="login()" />
                        <BaseInput class="mt-3" type="password" placeholder="senha" v-model="password" maxlength="40" @keyup.enter="login()" />
                        <button class="mt-4 w-full btn btn-primary" type="button" @click="login()">
                            <svg v-if="loading" class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{ loading ? 'Verificando...' : 'Login' }}
                        </button>
                        <div class="flex justify-center">
                            <BaseErrorMessage :message="authError" />
                        </div>
                        <p class="text-center mt-24 text-sm text-green-500 font-semibold">
                            Alta capacidade técnica | Credibilidade | Resultados
                        </p>
                        <p class="text-center text-sm text-blue-900 font-semibold">
                            © 2022 Megaluzz Negócios
                        </p>
                    </form>

                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import AuthService from '../services/AuthService';
import router from "@/router";

export default {
    name: 'Login',
    setup() {
        let loading = ref(false);
        let authError = ref(null);
        const username = ref();
        const password = ref();

        async function login() {
            try {
                loading.value = true;
                const { data: { data } } = await AuthService.authenticate(username.value, password.value);

                loading.value = false;

                AuthService.storeLocalData({ ...data });
                authError.value = null;
                router.push({ name: 'team' });
            } catch(err) {
                authError.value = 'Email ou senha inválido(a)';
                console.log(err);
                loading.value = false;
            }
        }
        
        return { loading, login, authError, username, password };
    }
}
</script>