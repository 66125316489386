<template>
<div>
	<div class="flex">
		<label v-if="label" class="label">
			{{ label }}
			<BaseRequiredStar v-if="$attrs.required" />
		</label>
		<slot name="help"></slot>
	</div>

	<input
		v-bind="$attrs"
		ref="inputRef"
		type="text"
		:value="formattedValue"
		@input="$emit('update:modelValue', $event.target.value)"
	>
	<slot name="tip"></slot>
</div>

</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';

export default {
	name: 'CurrencyInput',
	props: {
		label: {
			type: String,
			default: ''
		}, 
		modelValue: {
			type: [String, Number],
			default: ''
		}
	},
	setup (props) {
		const options = {
			locale: 'pt-BR',
			currency: 'BRL',
			currencyDisplay: 'symbol',
			valueRange: undefined,
			precision: 2,
			hideCurrencySymbolOnFocus: false,
			hideGroupingSeparatorOnFocus: false,
			hideNegligibleDecimalDigitsOnFocus: false,
			autoDecimalDigits: true,
			exportValueAsInteger: false,
			autoSign: true,
			useGrouping: true
		};
		const { formattedValue, inputRef } = useCurrencyInput(options);

		return { inputRef, formattedValue, options }
	}
}
</script>