<template>
    <div class="flex items-center justify-center mb-12">
        <label for="toggle" class="flex items-center cursor-pointer">
            <div class="relative">
                <input 
                    id="toggle"
                    type="checkbox"
                    class="sr-only"
                    :checked="modelValue"
                    @change="$emit('update:modelValue', $event.target.checked)"
                />
                <div id="sliderArea" class="w-10 h-4 bg-gray-300 rounded-full shadow-inner"></div>
                <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
            </div>
            <div v-if="label" class="checkbox-label">
                {{ label }}
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'BaseSlider',
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Boolean],
            default: ''
        },
    }
}
</script>


<style scoped>
    input:checked ~ .dot {
        transform: translateX(100%);
        background-color: #34D399;
    }

    input:checked ~ #sliderArea {
        background-color: #D1FAE5;
    }
</style>