
import HttpClient from '../HttpService';

function list(page, limit, data) {
    return HttpClient.post(`/megafiles/list?page=${page}&limit=${limit}`, data);
}

export default {
    list,
}
