<template>
    <div>
        <div class="table-container" v-if="files.length">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        <th>Arquivo</th>
                        <th>Tipo</th>
                        <th>Tamanho</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="file in files" :key="file.id">
                        <td>
                            <BaseButton skin="primary-outline-small" :external="true" :href="file.url">Ver</BaseButton>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-blue-900">{{ file.description.substring(0, 35) }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-blue-900">{{ getExtension(file.description).substring(0, 5) }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-blue-900">{{ humanFileSize(file.size) }}</span>
                        </td>
                        <td>
                            <BaseButton skin="danger-small" @click="removeFile(file.id)" v-if="deleteControl">
                                <template v-slot:icon>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </template>
                            </BaseButton>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center" v-if="loading">
                <span class="text-green-500 p-4">
                    Atualizando arquivos...
                </span>
                <svg class="animate-spin mt-4 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FileTable',
    props: {
        files: Array,
        loading: Boolean,
        deleteControl: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        function getExtension(filename) {
            return filename.split('.').pop();
        }

        function removeFile(fileId) {
            context.emit('remove-file', fileId);
        }
 
        function humanFileSize(size) {
            let i = Math.floor( Math.log(size) / Math.log(1024) );
            return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
        }

        return { humanFileSize, getExtension, removeFile }
    }
}
</script>