<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BaseButton :to="{ name: 'job', params: {} }" skin="primary">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </template>
                Adicionar
            </BaseButton>
        </ActionArea>

        <div class="grid grid-cols-5 mb-6 gap-x-4 card p-12" v-if="!loadingEntireScreen">
            <div>
                <BaseInput label="Filtrar por cargo" v-model="filter.role" v-on:keyup.enter="loadData" />
            </div>
            <div class="flex flex-col">
                <label class="label">Filtrar Data Inicial</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.start_date" autoApply @update:modelValue="loadData" />
            </div>
            <div class="flex flex-col">
                <label class="label">Filtrar Data Final</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.end_date" autoApply @update:modelValue="loadData" />
            </div>
        </div>
        
        <div class="flex justify-center mb-4">
            <Loading text="Buscando solicitações... ..." :show="loading" />
        </div>

        <Alert text="Não existem processos seletivos para esse filtro" :show="!jobs.length && !loadingEntireScreen" />

        <div class="table-container" v-show="jobs.length && !loading && !loadingEntireScreen">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        <th>Cargo</th>
                        <th>Quant. Vagas</th>
                        <th>Data Prevista</th>
                        <th>Prazo</th>
                        <th>Situação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="job in jobs" :key="job.id">
                        <td class="text-left text-sm leading-5 flex gap-x-8">
                            <BaseButton skin="primary-outline" :to="{ name: 'job', params: { id: job.id } }">Gerenciar</BaseButton>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700 font-semibold">{{ job.role }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ job.amount }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ DateTime.fromISO(job.created_at).plus({ days: job.deadline }).toFormat('dd/MM/yyyy') }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ job.deadline }} dias</span>
                        </td>
                        <td>
                            <Tag v-if="job.status" type="success" text="Aberto" />
                            <Tag v-if="!job.status" type="info" text="Finalizado" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total="totalItens" :limit="limit" @changePage="changePagination" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import PageHeader from '@/components/PageHeader';
import Tag from '@/components/Tag';
import { JobService } from '../services/Entity';
import Alert from '@/components/Alert';
import Pagination from '@/components/Pagination';
import Swal from 'sweetalert';
import { defaultDate } from '../filters/date';
import { DateTime } from 'luxon';

export default {
    components: {
        PageHeader,
        Tag,
        Alert,
        Pagination
    },
    setup() {
        const totalItens = ref(0);
        const page = ref(1);
        const limit = ref(20);
        const loadingEntireScreen = ref(false);
        const pageTitle = ref('Processos Seletivos');
        const jobs = ref([]);
        const loading = ref(false);
        const filter = ref({
            start_date: DateTime.now().minus({ days: 30 }),
            end_date: DateTime.now() 
        });
        
        async function init() {
            loadingEntireScreen.value = true;
            await loadData();
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            if (!filter.value.start_date || !filter.value.end_date) {
                return;
            }

            loading.value = true;
            await loadJobs(filter.value);
            loading.value = false;
        }

        async function loadJobs(filter) {
            try {
                const { data: { total, items } } = await JobService.list(page.value, limit.value, filter);

                jobs.value = items;
                totalItens.value = total;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos processos seletivos',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadJobs(page.value, limit.value, filter.value);
            loading.value = false;
        }

        init();
        
        return {
            DateTime,
            loadData,
            totalItens,
            pageTitle,
            changePagination,
            filter,
            defaultDate,
            jobs,
            loading,
            page,
            limit,
            loadingEntireScreen
        };
    },
}
</script>