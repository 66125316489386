import uuidv1 from 'uuid/v1';

function removeSpecialChar(text) {       
    text = text.toLowerCase();                                                         
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    text = text.replace(new RegExp('[Ç]','gi'), 'c');
    return text;                 
}

function slugfy(text) {
    const sanitizedText =  removeSpecialChar(text);
    return sanitizedText.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
}

function generateUUID() {
	return uuidv1();
}

function generateHexColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
}

function scrollTo(element, currentWindow) {
    currentWindow.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop
    });
}

export default {
    slugfy,
    scrollTo,
    generateUUID,
    generateHexColor,
    removeSpecialChar
}