import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './index.css';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import PageHeader from '../src/components/PageHeader';
import ActionArea from '../src/components/ActionArea';
import BaseButton from '../src/components/BaseButton';
import Loading from '../src/components/Loading';
import Maska from 'maska';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

const requireComponent = require.context('./components/forms', false, /Base[A-Z]\w+\.(vue|js)$/);
const app = createApp(App)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
    )

    app.component(componentName, componentConfig.default || componentConfig)
});

app.use(Maska);
app.component('PageHeader', PageHeader);
app.component('ActionArea', ActionArea);
app.component('Datepicker', Datepicker);
app.component('BaseButton', BaseButton);
app.component('Loading', Loading);
app.use(router).mount('#app');
