
import HttpClient from '../HttpService';

function toggleStatus(adminId) {
    return HttpClient.put(`/admins/${adminId}/toggle/status`);
}

function getById(adminId) {
    return HttpClient.get(`/admins/${adminId}`);
}

function create(data) {
    return HttpClient.post(`/admins/`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/admins/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/admins/${data.id}`, data);
}

function resetPassword(data) {
    return HttpClient.put(`/admins/${data.id}/reset/password`, data);
}

export default {
    list,
    create,
    update,
    resetPassword,
    toggleStatus,
    getById
}
