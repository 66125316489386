import HttpClient from './HttpService';

const UPLOAD_ENDPOINT = process.env.VUE_APP_ROOT_UPLOAD_ENDPOINT;

function upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append('files', file);

    return HttpClient.post(UPLOAD_ENDPOINT, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress
    });
}


export default {
    upload
}