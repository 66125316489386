<template>
	<div>
		<label class="inline-flex items-center">
			<input 
				class="checkbox-input"
				type="checkbox"
				:id="id"
				:checked="modelValue"
				@change="$emit('update:modelValue', $event.target.checked)"
			/>
			<span v-if="label" class="checkbox-label">{{ label }}</span>
		</label>
		<slot name="tip"></slot>
	</div>
</template>

<script>
export default {
	name: 'BaseCheckbox',
	props: {
		id: {
			type: [String, Number],
			required: true
		},
		label: {
			type: String,
			default: ''
		},
		modelValue: {
			type: Boolean,
			default: false
		}
	}
}
</script>