
import HttpClient from '../HttpService';


function generateReport(data) {
    return HttpClient.post('/reports', data);
}

export default {
    generateReport
}
