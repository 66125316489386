<template>
    <div>
        <div class="flex">
            <label v-if="label" class="label">
                {{ label }}
                <BaseRequiredStar v-if="$attrs.required" />
            </label>
            <slot name="help"></slot>
        </div>
        <input
            type="text"
            :class="{ 'input-error': isInvalid }"
            v-bind="$attrs"
            :maxlength="$attrs.maxlength || defaultMaxLength"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >
        <slot name="tip"></slot>
    </div>
</template>

<script>

import { ref } from 'vue';

export default {
    props: {
        label: {
            type: String,
            default: ''
        },  
        modelValue: {
            type: [String, Number],
            default: ''
        },
        isInvalid: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const defaultMaxLength = ref(100);

        return { defaultMaxLength };
    }
}
</script>