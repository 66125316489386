<template>
    <div class="grid grid-cols-3">
        <div class=py-4>
            <span class="label mt-2">Mostrando <strong>{{ (currentPage * limit) - limit }} á {{ (currentPage * limit) > total ? total : (currentPage * limit) }}</strong> de <strong>{{ total }}</strong> resultados</span>
        </div>
        <div class="flex justify-center">
            <nav class="py-4">
                <ul class="inline-flex">
                    <li>
                        <button class="pagination-outer-button" @click="changePage(1)">
                            <li><button class="pagination-button">Primeira</button></li>
                        </button>
                    </li>
                    <li>
                        <button class="rounded-l-lg pagination-button" @click="changePage(currentPage - 1)">
                            <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                        </button>
                    </li>

                    
                    <li v-for="pageIndex in Math.ceil(total / limit)" :key="pageIndex">
                        <button v-if="currentPage != pageIndex" class="h-10 pagination-button" @click="changePage(pageIndex)">{{ pageIndex }}</button>
                        <button v-if="currentPage == pageIndex" :class="{ 'h-10 px-5 text-white transition-colors duration-150 bg-green-500 focus:shadow-outline': (currentPage === pageIndex) }">{{ pageIndex }}</button>
                    </li>

                    <li>
                        <button class="rounded-r-lg pagination-button"  @click="changePage(currentPage + 1)">
                            <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                        </button>
                    </li>
                    <li>
                        <button class="pagination-outer-button" @click="changePage(Math.ceil(total / limit))">
                            <li><button class="pagination-button">Última</button></li>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';

export default {
    name: 'Pagination',
    props: {
        total: Number,
        limit: Number
    },
    setup(props, context) {
        const currentPage = ref(1);
        const totalPages = ref(Math.ceil(props.total / props.limit));

        function changePage(page) {
            if (!page) return;
            if (page > (Math.ceil(props.total / props.limit))) return;

            currentPage.value = page;
            context.emit('changePage', currentPage.value);
        }

        return {
            totalPages,
            changePage,
            currentPage
        }
    }
}
</script>