import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = 'pt-br';

function defaultDate(value) {
    return DateTime.fromISO(value).toFormat('dd/MM/yyyy');
}

function jsDate(value) {
    return DateTime.fromJSDate(value).toFormat('dd/MM/yyyy');
}

function toMysql(value) {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss');
}

function dateTime(value) {
    return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm');
}

function hoursAgo(value) {
    return DateTime.fromISO(value).toRelative({ unit: 'hours'});
}

function daysAgo(value) {
    return DateTime.fromISO(value).toRelative({ unit: 'days'});
}

function weeksAgo(value) {
    return DateTime.fromISO(value).toRelative({ unit: 'weeks'});
}

function monthsAgo(value) {
    return DateTime.fromISO(value).toRelative({ unit: 'months'});
}

function yearsAgo(value) {
    return DateTime.fromISO(value).toRelative({ unit: 'years'});
}

export {
    jsDate,
    toMysql,
    defaultDate,
    dateTime,
    hoursAgo,
    daysAgo,
    weeksAgo,
    monthsAgo,
    yearsAgo
}