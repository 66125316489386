
import HttpClient from '../HttpService';

function getById(requestId) {
    return HttpClient.get(`/requests/${requestId}`);
}

function create(data) {
    return HttpClient.post(`/requests`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/requests/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/requests/${data.id}`, data);
}

function removeFile(requestId, fileId, data) {
    return HttpClient.delete(`/requests/${requestId}/files/${fileId}`, data);
}

export default {
    list,
    create,
    update,
    removeFile,
    getById
}
