<template>
	<div>
		<div class="flex">
			<label v-if="label" class="label">
				{{ label }}
				<BaseRequiredStar v-if="$attrs.required" />
			</label>
			<slot name="help"></slot>
		</div>
		<div class="mt-4" v-for="option in options" :key="option.uuid">
			<BaseCheckbox
                :id="option.uuid"
                :label="option.title"
                :modelValue="modelValue"
				@update:modelValue="$emit('update:modelValue', $event)"
			/>
		</div>
		<slot name="tip"></slot>
	</div>
</template>

<script>
export default {
	props: {
		label: {
            type: String,
            default: ''
        },
		options: {
			type: Array,
			required: true
		},
		modelValue: {
			type: [String, Number],
			required: true
		}
	}
}
</script>