<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="{ name: 'requests' }" />
        </ActionArea>

        <div class="flex justify-center">
            <Loading text="Buscando solicitações... ..." :show="loading" />
        </div>

        <div class="grid grid-cols-1" v-if="!loading">
            <div class="card p-10">
                <div class="grid grid-cols-4">
                    <div>
                        <label class="label">Cliente Solicitante</label>
                        <span class="text-green-500 text-sm font-semibold">{{ request.user.name }}</span>
                    </div>
                    <div>
                        <label class="label">Colaborador</label>
                        <span class="text-green-500 text-sm font-semibold">{{ request.admin.name }}</span>
                    </div>
                    <div>
                        <label class="label">Tipo de Solicitação</label>
                        <span class="text-sm leading-5 text-green-900" v-if="request.type === 'meeting'">Reunião</span>
                        <span class="text-sm leading-5 text-green-900" v-if="request.type === 'job'">Processo Seletivo</span>
                        <span class="text-sm leading-5 text-green-900" v-if="request.type === 'other'">Outros</span>
                    </div>
                    <div>
                        <label class="label">Status</label>
                        <Tag v-if="request.status === 'waiting'" type="warning" text="aguardando" />
                        <Tag v-if="request.status === 'in_progress'" type="info" text="em progresso" />
                        <Tag v-if="request.status === 'done'" type="success" text="feito" />
                        <Tag v-if="request.status === 'canceled'" type="danger" text="cancelada" />
                    </div>
                </div>
                <div class="grid grid-cols-1 mt-10">
                    <div>
                        <label class="label">Descrição</label>
                        <span class="text-green-500 text-sm font-semibold">{{ request.description }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-5" v-if="!loading">
            <div class="col-span-4">
                <FileTable class="mt-2" v-if="request.files && request.files.length" :files="request.files" :delete-control="false" />
            </div>
        </div>

        <div class="grid grid-cols-1 mt-4" v-if="!loading">
            <div class="card p-10">
                <div>
                    <BaseTextarea rows="7" label="Adicionar um comentário" v-model="requestNote.description" :maxlength="10000" id="requestNoteField" />
                    <FileUploader class="mt-4" text="Adicionar Arquivos" :multiple="true" :showProgress="true" accept="*" @onUpload="onUpload" />
                    <FileTable class="mt-2" :loading="loadingFiles" :files="requestNote.files" @removeFile="removeRequestNoteFile" />
                    <div class="flex justify-end mt-8">
                        <BaseButton skin="primary" @click="submit" :disabled="loadingSubmitComment || !requestNote.description">
                        <svg v-if="loadingSubmitComment" class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>

                        {{ isEditComment ? 'Atualizar': 'Enviar Comentário' }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 mt-4" v-if="!requestNotes.length && !loading">
            <Alert text="Ainda não existem comentários" :show="!requestNotes.length && !loading" />
        </div>

        <div class="flex justify-center">
            <Loading text="Atualizando comentários..." :show="loadingRequestNotes" />
        </div>

        <div class="grid grid-cols-1 mt-4" v-if="!loadingRequestNotes">
            <div v-for="requestnote in requestNotes" :key="requestnote.id" v-show="requestNotes.length">
                <RequestNoteCard @editNote="editNote" class="mt-4" :requestnote="requestnote" :logged-user-id="loggedAdmin.id" />
                <FileTable class="mt-2" v-if="requestnote.files && requestnote.files.length" :files="requestnote.files" :delete-control="false" />
            </div>
        </div>

    </div>
</template>

<script>

import { ref } from 'vue';
import { RequestService, RequestNoteService } from '../services/Entity';
import Swal from 'sweetalert';
import { defaultDate } from '../filters/date';
import { useRoute, useRouter } from 'vue-router';
import Tag from '@/components/Tag';
import BackButton from '@/components/BackButton';
import FileTable from '@/components/FileTable';
import FileUploader from '@/components/FileUploader';
import RequestNoteCard from '@/components/RequestNoteCard';
import Alert from '@/components/Alert';
import AuthService from '../services/AuthService';
import UtilService from '../services/UtilService';

export default {
    components: {
        Tag,
        BackButton,
        FileTable,
        FileUploader,
        RequestNoteCard,
        Alert
    },
    setup() {
        const pageTitle = ref('Detalhes da Solicitação');
        const requestNotes = ref([]);
        const loadingFiles = ref(false);
        const isEditComment = ref(false);
        const loadingSubmitComment = ref(false);
        const loadingRequestNotes = ref(false);
        const loggedAdmin = AuthService.getAuthInfo();
        const requestNote = ref({
            files: []
        });
        const request = ref({
            admin: {},
            user: {}
        });
        const loading = ref(false);
        const loadingUpdate = ref(false);
        const route = useRoute();
        const router = useRouter();
        const statusOptions = ref([
            { title: 'Aguardando', value: 'waiting' },
            { title: 'Em progresso', value: 'in_progress' },
            { title: 'Finalizado', value: 'done' },
            { title: 'Cancelada', value: 'canceled' }
        ]);

        async function init() {
            loading.value = true;
            await loadData()
            loading.value = false;
        }

        async function loadData() {
            const requestId = handleRoute(route);
            await getRequestById(requestId);
            await loadRequestNotes();
        }

        function handleRoute(route) {
            if (!route.params.id) {
                router.push({ name: 'requests' });
                return;
            }

            return route.params.id;
        }

        async function getRequestById(requestId) {
            try {
                const { data } = await RequestService.getById(requestId);
                request.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados das solicitações',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            try {
                loadingUpdate.value = true;
                const requestObject  = { ...request.value };
                const { data } = await RequestService.update({ id: requestObject.id, status: requestObject.status, company: requestObject.company.id });
                request.value = data;
                loadingUpdate.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o status da solicitação',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadRequestNotes() {
            try {
                loadingRequestNotes.value = true;
                const listFilter = { page: 1, limit: 9999}
                const { data: { total, items } } = await RequestNoteService.list(listFilter.page, listFilter.limit, { request: request.value.id });
                requestNotes.value = items;
                loadingRequestNotes.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter os comentários',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            if (!isEditComment.value) {
                await saveComment();
            } else {
                await updateComment();
            }
        }

        function resetRequestNote() {
            isEditComment.value = false;
            requestNote.value = {};
            requestNote.value.description = '';
            requestNote.value.files = [];
        }

        async function saveComment() {
            try {
                loadingSubmitComment.value = true;
                const requestNoteObject  = { ...requestNote.value, request: request.value.id, admin: loggedAdmin.id, user: null };
                await RequestNoteService.create(requestNoteObject);
                resetRequestNote();
                loadingSubmitComment.value = false;
                await loadRequestNotes(request.value.id);
            } catch(err) {
                loading.value = false;
                loadingSubmitComment.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o status da solicitação',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function updateComment() {
            try {
                const requestNoteObject  = { ...requestNote.value };
                requestNoteObject.user = requestNoteObject.user.id;
                loadingSubmitComment.value = true;
                await RequestNoteService.update(requestNoteObject);
                loadingSubmitComment.value = false;
                resetRequestNote();
                await loadRequestNotes(request.value.id);
            } catch(err) {
                loading.value = false;
                loadingSubmitComment.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o comentário',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            requestNote.value.files.push(file);
        }

        async function removeRequestNoteFile(fileId) {
            try {
                Swal({
                title: 'Deseja realmente remover o arquivo?',
                text: 'Não será possível restaurar o mesmo',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Deletar',
                        className: 'swal-button--danger'
                    }
                }}).then(async (confirm) => {
                    if (!confirm) return;

                    const requestData = { requestnote: requestNote.value.id, file: fileId };
                    loadingFiles.value = true;
                    requestNote.value.files.forEach((file, index) => {
                        if (file.id == fileId) {
                            requestNote.value.files.splice(index, 1);
                        }
                    });

                    await RequestNoteService.removeFile(requestData.requestnote, requestData.file, requestData);
                    loadingFiles.value = false;
                });
            } catch(err) {
                loadingFiles.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao excluir o arquivo',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function editNote(requestnote) {
            isEditComment.value = true;
            UtilService.scrollTo(document.getElementById('requestNoteField'), window);
            requestNote.value = { ...requestnote, user: requestnote.user.id };
        }

        init();

        return {
            loadingRequestNotes,
            loggedAdmin,
            editNote,
            requestNotes,
            loadingSubmitComment,
            saveComment,
            submit,
            isEditComment,
            loadingFiles,
            loadRequestNotes,
            onUpload,
            updateComment,
            removeRequestNoteFile,
            requestNote,
            update,
            loadingUpdate,
            defaultDate,
            getRequestById,
            statusOptions,
            loadData,
            pageTitle,
            request,
            loading
        };
    },
}
</script>