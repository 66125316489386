
import HttpClient from '../HttpService';

function create(data) {
    return HttpClient.post(`/requestnotes`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/requestnotes/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/requestnotes/${data.id}`, data);
}

function removeFile(requestNoteId, fileId, data) {
    return HttpClient.delete(`/meetings/${requestNoteId}/files/${fileId}`, data);
}

export default {
    list,
    create,
    update,
    removeFile
}
