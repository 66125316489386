<template>
    <!-- navbar goes here -->
    <nav class="bg-green-500 border-b border-l">
        <div class="max-w-8xl px-6 flex justify-between">
            <!-- <div class="flex justify-start items-center">
                <svg xmlns="http://www.w3.org/2000/svg" id="toggleSidebar" class="cursor-pointer h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                </svg>
            </div> -->
            <div class="space-x-4 flex justify-end">
                <div class="hidden md:flex items-center space-x-1">
                    <span class="py-4 px-3 text-sm text-white font-semibold uppercase">AUTENTICADO COMO: {{ userName }}</span>
                </div>
            </div>
            <div class="space-x-4 flex justify-end">
                <div class="hidden md:flex items-center space-x-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    <span @click="logout" class="p-2 w-20 text-center text-sm text-green-500 bg-white rounded-md font-semibold cursor-pointer uppercase">Sair</span>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import AuthService from '../services/AuthService';
import { ref } from 'vue';

export default {
    name: 'Navbar',
    emits: ['toggleSidebar'],
    components: {
    },
    setup(props, context) {
        let userName = ref(null);

        if (AuthService.isAuthenticated()) {
            getLoggedUserInfo();
        }

        function logout() {
            AuthService.logout();
        }

        function getLoggedUserInfo() {
            const { name, photo_url } = AuthService.getAuthInfo();

            userName.value = name;
        }

        return { logout, userName };
    }
}
</script>
