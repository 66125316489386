<template>
<div>
    <router-link v-if="to && !isSubmit && !external" :to="to" :class="buttonStyle">
        <button class="flex gap-x-2">
            <slot name="icon"></slot>
            <slot />
        </button>
    </router-link>
    <button type="button" v-if="!to && !isSubmit && !external" :class="buttonStyle" :disabled="disabled" class="flex gap-x-2">
        <slot name="icon"></slot>
        <slot />
    </button>
    <button type="submit" v-if="isSubmit" :class="buttonStyle" :disabled="disabled" class="flex gap-x-2">
        <slot name="icon"></slot>
        <slot />
    </button>
    <a target="_blank" type="button" :href="href" v-if="external" :class="buttonStyle" :disabled="disabled" class="flex gap-x-2">
        <slot name="icon"></slot>
        <slot />
    </a>
</div>
</template>

<script>

import { ref } from 'vue';

export default {
    name: 'BaseButton',
    props: {
        to: {
            type: [String, Object],
            default: null
        },
        skin: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isSubmit: {
            type: Boolean
        },
        href: String,
        external: Boolean
    },
    setup (props) {
        const buttonStyle = ref(getButtonStyle(props.skin));

        function getButtonStyle(buttonStyle) {
            if (buttonStyle === 'primary') return 'btn btn-primary'
            if (buttonStyle === 'primary-small') return 'btn btn-sm btn-primary';
            if (buttonStyle === 'primary-outline') return 'btn btn-primary-outline';
            if (buttonStyle === 'primary-outline-small') return 'btn btn-sm btn-primary-outline';

            if (buttonStyle === 'danger') return 'btn btn-danger'
            if (buttonStyle === 'danger-small') return 'btn btn-sm btn-danger';
            if (buttonStyle === 'danger-outline') return 'btn btn-danger-outline';
            if (buttonStyle === 'danger-outline-small') return 'btn btn-sm btn-danger-outline';
        }

        return { buttonStyle }
    }
}
</script>