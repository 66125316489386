<template>
	<div class="flex">
		<Sidebar v-if="!['auth', 'login'].includes(routeName) && isSidebarVisible"></Sidebar>
		
        <div class="flex-1 bg-gray-100">
			<Navbar @toggleSidebar="toggleSidebar" v-if="!['auth', 'login'].includes(routeName)"></Navbar>
			
			<div class="p-10">
				<router-view v-slot="{ Component }">
					<Suspense timeout="0">
						<template #default>
							<component :is="Component" />
						</template>
						<template #fallback>
							<Loading></Loading>
						</template>
					</Suspense>
				</router-view>
			</div>
        </div>
	</div>
</template>

<style scoped>
	.slide-fade-enter-active {
		transition: all 0.2s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateX(-20px);
		opacity: 0;
	}
</style>

<script>
import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar';
import Loading from '@/components/Loading';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
	components: {
		Sidebar,
		Navbar,
		Loading
	},
	setup() {
		const isSidebarVisible = ref(true);
		const isLogged = ref(false);
		const route = useRoute();
		let routeName = ref(route.name);

		watch(() => route.name, () => {
			routeName.value = route.name;
		}, { immediate: true });

		function toggleSidebar() {
			isSidebarVisible.value = !isSidebarVisible.value;
		}

		return { isSidebarVisible, isLogged, toggleSidebar, routeName };
	}
}
</script>
