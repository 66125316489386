import CryptoJS from 'crypto-js';

const SECRET = process.env.VUE_APP_HASH_KEY;

function set(key, value) {
    const encodedKey = CryptoJS.SHA256(key).toString();
    const encodedValue = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET);

    localStorage.setItem(encodedKey, encodedValue);
}

function get(key) {
    const encodedKey = CryptoJS.SHA256(key).toString();
	const encodedValue = localStorage.getItem(encodedKey);

    if (!encodedValue) {
        return;
    }
    
    return CryptoJS.AES.decrypt(encodedValue, SECRET).toString(CryptoJS.enc.Utf8);
}

function remove(key) {
    const encodedKey = CryptoJS.SHA256(key).toString()
	localStorage.removeItem(encodedKey);
}

function clear() {
    localStorage.clear();
}

export default {
    set,
    get,
    remove,
    clear
}