<template>
    <div class="flex justify-start mb-8 gap-x-4">
        <slot></slot>
    </div>
</template>

<script>
export default {
    setup(props, context) {
        
    },
}
</script>