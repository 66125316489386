<template>
    <span class="p-1 text-xs font-bold text-blue-400" v-if="tip">{{ tip }}</span>
</template>

<script>
export default {
    props: {
        tip: String
    }
}
</script>
