
import HttpClient from './HttpService';
import LocalStorage from './LocalStorageService';
import router from "@/router";

function authenticate(username, password) {
    return HttpClient.post('/auth/authenticate', { username, password });
}

function storeLocalData({ token, user }) {
    LocalStorage.clear();

    const loggedUser = {
        token,
        id: user.id,
        name: user.name,
        photo_url: user.photo_url,
        company: user.company
    };

    LocalStorage.set('authenticated_user', loggedUser );
}

function getAuthInfo() {
    if (!LocalStorage.get('authenticated_user')) {
        return false;
    }

    return JSON.parse(LocalStorage.get('authenticated_user'));
}

function isAuthenticated() {
    if (!LocalStorage.get('authenticated_user')) {
        return false;
    }

    const { token, id } = JSON.parse(LocalStorage.get('authenticated_user'));

    return (token && id) ? true : false;
}

function logout() {
    LocalStorage.clear();
    router.push({ name: 'login' });
}

export default {
    logout,
    authenticate,
    storeLocalData,
    getAuthInfo,
    isAuthenticated
}