<template>
	<label class="radio-container">
		<input 
			class="radio-input"
			type="radio"
			:checked="modelValue === value"
			:value="value"
			v-bind="$attrs"
			@change="$emit('update:modelValue', value)"
		/>
		<span class="radio-label" v-if="label">{{ label }}</span>
	</label>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		},
		modelValue: {
			type: [String, Number],
			default: ''
		},
		value: {
			type: [String, Number],
			required: true
		}
	}
}
</script>