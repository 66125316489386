<template>
    <div class="card">
        <div class="card-header-container">
            <h1 class="card-header-title">{{ title }}</h1>
            <h2 class="-mt-4 ml-5 font-medium text-md text-gray-400" v-if="subtitle">{{ subtitle }}</h2>
        </div>
        <div class="p-12">
            <slot name="form"></slot>
        </div>
        <div class="px-12 pb-12 flex justify-end gap-x-4">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String
    }
}
</script>
