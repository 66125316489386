<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
        </PageHeader>

        <div class="flex justify-center mb-6" v-if="loading">
            <Loading text="Buscando Dados  Aguarde..." :show="loading" />
        </div>

        <div class="grid grid-cols-3 gap-x-2" v-if="!loading">
            <div class="col-span-2">
                <form @submit.prevent="submit" novalidate>
                    <BaseFormContainer :title="formTitle">
                        <template v-slot:form>
                                <BaseFormSectionHeader title="Sua foto" subtitle="Escolha uma foto para o seu perfil"></BaseFormSectionHeader>

                                <div class="p-8 flex">
                                    <ThumbRounded class="mr-12" size="large" :path="user.photo_url" />
                                    <FileUploader class="mt-4" text="Enviar Foto" :showProgress="true" :accept="acceptMimeTypeImage" @onUpload="onUpload" />
                                </div> 

                                <BaseFormSectionHeader title="Informações Pessoais" subtitle="Informações Básicas"></BaseFormSectionHeader>
                                <div class="grid grid-cols-3 form-row">
                                    <div>
                                        <BaseInput :is-invalid="v$.name.$error" label="Nome e Sobrenome" @blur="v$.name.$touch" placeholder="Leonardo Da Vinci" v-model="user.name" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'name'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput :is-invalid="v$.email.$error" disabled="true" label="Email" @blur="v$.email.$touch" placeholder="leonardo@davinci.com" v-model="user.email" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'email'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput label="Telefone" placeholder="(11) 99999-9999" v-model="user.phone" v-maska="MaskService['phone']"/>
                                    </div>
                                </div>
                        </template>
                        <template v-slot:actions>
                            <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                        </template>
                    </BaseFormContainer>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import MaskService from '../services/MaskService';
import ThumbRounded from '@/components/ThumbRounded';
import FileUploader from '@/components/FileUploader';
import Swal from 'sweetalert';
import { useVuelidate } from '@vuelidate/core';
import { UserService } from '../services/Entity';
import AuthService from '../services/AuthService';
import { 
    requiredMessage,
    minLengthMessage,
    emailMessage
} from '../services/VuelidateMessageService';

export default {
    name: 'Profile',
    components: {
        ThumbRounded,
        FileUploader,
    },
    props: {
        company: [Number, String]
    },
    setup(props) {
        const pageHeader = ref('Gerenciamento de Usuário');
        const formTitle = ref('Adicionar novo usuário');
        const acceptMimeTypeImage = 'image/png,image/gif,image/jpeg,image/webp';
        const route = useRoute();
        const isEdit = ref(true);
        const loading = ref(false);
        const upload = ref();
        const user = ref({
            name: '',
            status: true
        });

        const rules = computed(() => ({
            name: { required: requiredMessage, minLength: minLengthMessage(3) },
            email: { required: requiredMessage, email: emailMessage },
        }));
        
        async function init() {
            loading.value = true;
            await loadData();
            loading.value = false;
        }

        async function loadData() {
            const loggedUser = AuthService.getAuthInfo();

            isEdit.value = true;
            await getUserById(loggedUser.id);
            pageHeader.value = `Meu Perfil`;
        }

        async function getUserById(userId) {
            try {
                const { data } = await UserService.getById(userId);

                user.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados do usuário desta empresa',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            user.value.photo = file.id;
            user.value.photo_url = file.url;
        }

        async function submit() {
            await update();
        }

        async function update() {
            const userObject = { ...user.value };
            
            try {
                loading.value = true;
                const { data } = await UserService.update(userObject);
                await getUserById(data.id);
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'user' && (from != to)) {
                await init();
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, user);

        return {
            pageHeader,
            formTitle,
            user,
            MaskService,
            isEdit,
            acceptMimeTypeImage,
            upload,
            onUpload,
            v$,
            submit,
            update,
            loading
        };
    }
}
</script>

