
import HttpClient from '../HttpService';

function toggleStatus(companyId) {
    return HttpClient.put(`/companies/${companyId}/toggle/status`);
}

function getById(companyId) {
    return HttpClient.get(`/companies/${companyId}`);
}

function create(data) {
    return HttpClient.post(`/companies/`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/companies/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/companies/${data.id}`, data);
}

export default {
    list,
    create,
    update,
    toggleStatus,
    getById
}
