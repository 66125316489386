import {
    minLength,
    maxLength,
    required,
    integer,
    email,
    minValue,
    maxValue,
    between,
    alpha,
    alphaNum,
    sameAs,
    url,
    not,
    helpers
} from '@vuelidate/validators';

const requiredMessage = helpers.withMessage('Este campo é obrigatório', required);
const emailMessage = helpers.withMessage('Email Inválido', email);
const alphaMessage = helpers.withMessage('Este campo aceita apenas letras', alpha);
const alphaNumMessage = helpers.withMessage('Este campo aceita apenas letras e números', alphaNum);
const integerMessage = helpers.withMessage('Este campo deve ser numérico', integer);
const siteMessage = helpers.withMessage('Site inválido. ex: https://google.com', url);
const sameAsMessage = (value) => helpers.withMessage(`O valor precisa ser igual a ${value}`, sameAs(value));
const notMessage = (value) => helpers.withMessage(`O valor não pode ser ${value}`, not(sameAs(value)));
const minLengthMessage = (value) => helpers.withMessage(`Este campo deve ter no mínimo ${value} caracteres(s)`, minLength(value));
const maxLengthMessage = (value) => helpers.withMessage(`Este campo deve ter no máximo ${value} caractere(s)`, maxLength(value));
const minValueMessage = (value) => helpers.withMessage(`O valor mínimo é ${value}`, minValue(value));
const maxValueMessage = (value) => helpers.withMessage(`O valor máximo é ${value}`, maxValue(value));
const betweenMessage = (valueA, valueB) => helpers.withMessage(`O valor deve estar entre ${valueA} e ${valueB}`, between(valueA, valueB));


export {
    requiredMessage,
    minLengthMessage,
    maxLengthMessage,
    emailMessage,
    integerMessage,
    minValueMessage,
    maxValueMessage,
    betweenMessage,
    alphaMessage,
    alphaNumMessage,
    siteMessage,
    sameAsMessage,
    notMessage
}