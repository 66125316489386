<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <div class="grid grid cols-1 mb-4" v-if="!loadingEntireScreen">
            <BaseFormContainer title="Filtrar por:">
                    <template v-slot:form>
                        <form>
                            <div class="grid grid-cols-6 gap-x-8">
                                <div class="flex flex-col">
                                    <label class="label">De</label>
                                    <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.start_date" autoApply @update:modelValue="loadData" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="label">Até</label>
                                    <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.end_date" autoApply @update:modelValue="loadData" />
                                </div>
                                <div>
                                    <BaseSelect :options="statusOptions" emptyOption="Todos" label="Status" v-model="filter.status" @change="loadData" />
                                </div>
                                <div>
                                    <BaseSelect :options="typeOptions" emptyOption="Todos" label="Tipo" v-model="filter.type" @change="loadData" />
                                </div>
                            </div>
                        </form>
                    </template>
            </BaseFormContainer>
        </div>

        <div class="flex justify-center">
            <Loading text="Buscando solicitações... ..." :show="loading" />
        </div>

        <Alert text="Não existem solicitações para esse filtro" :show="!requests.length && !loading" />

        <div class="table-container" v-if="requests.length && !loadingEntireScreen">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        <th>Data</th>
                        <th>Status</th>
                        <th>Tipo</th>
                        <th>Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="request in requests" :key="request.id">
                        <td class="text-left text-sm leading-5 flex gap-x-8">
                            <BaseButton skin="primary-outline" :to="{ name: 'request', params: { id: request.id }}">Ver</BaseButton>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ defaultDate(request.created_at) }}</span>
                        </td>
                        <td>
                            <Tag v-if="request.status === 'waiting'" type="warning" text="aguardando" />
                            <Tag v-if="request.status === 'in_progress'" type="info" text="em progresso" />
                            <Tag v-if="request.status === 'done'" type="success" text="feito" />
                            <Tag v-if="request.status === 'canceled'" type="danger" text="cancelada" />
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900" v-if="request.type === 'meeting'">Reunião</span>
                            <span class="text-sm leading-5 text-green-900" v-if="request.type === 'job'">Processo Seletivo</span>
                            <span class="text-sm leading-5 text-green-900" v-if="request.type === 'other'">Outros Assuntos</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ request.description.substring(0, 28) }}...</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total="totalRequests" :limit="limit" @changePage="changePagination" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import PageHeader from '@/components/PageHeader';
import Tag from '@/components/Tag';
import { RequestService, UserAdminService, CompanyService } from '../services/Entity';
import Alert from '@/components/Alert';
import Pagination from '@/components/Pagination';
import Swal from 'sweetalert';
import { defaultDate } from '../filters/date';
import { DateTime } from 'luxon';

export default {
    components: {
        PageHeader,
        Tag,
        Alert,
        Pagination
    },
    setup() {
        const totalRequests = ref(0);
        const page = ref(1);
        const limit = ref(20);
        const pageTitle = ref('Solicitações');
        const requests = ref([]);
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const typeOptions = ref([
            { title: 'Processo Seletivo', value: 'job' },
            { title: 'Reunião', value: 'meeting' },
            { title: 'Outros Assuntos', value: 'others' }
        ]);
        const statusOptions = ref([
            { title: 'Aguardando', value: 'waiting' },
            { title: 'Em progresso', value: 'in_progress' },
            { title: 'Finalizado', value: 'done' },
            { title: 'Cancelada', value: 'canceled' }
        ]);
        const filter = ref({
            start_date: DateTime.now().minus({ days: 30 }),
            end_date: DateTime.now() 
        });

        async function init() {
            loadingEntireScreen.value = true;
            await loadData()
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadRequests(filter.value);
            loading.value = false;
        }

        async function loadRequests(filter) {
            try {
                const { data: { total, items } } = await RequestService.list(page.value, limit.value, filter);

                requests.value = items;
                totalRequests.value = total;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados das solicitações',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadRequests(page.value, limit.value, filter.value);
            loading.value = false;
        }

        init();

        return {
            defaultDate,
            loadRequests,
            loadData,
            statusOptions,
            typeOptions,
            totalRequests,
            limit,
            page,
            pageTitle,
            filter,
            requests,
            loading,
            loadingEntireScreen,
            changePagination
        };
    },
}
</script>