<template>
    <div class="mt-2">
        <span class="error-msg" v-if="message">{{ message }}</span>
    </div>
</template>

<script>
export default {
    props: {
        message: String
    }
}
</script>