<template>
    <span 
        :class="{ 
                'pill pill-success': type === 'success',
                'pill pill-danger': type === 'danger',
                'pill pill-warning': type === 'warning',
                'pill pill-info': type === 'info',
                'pill pill-purple': type === 'alternative',
                'text-gray-800': textType === 'dark'
        }">{{ text }}</span>
</template>

<script>
export default {
    props: {
        text: String,
        textType: String,
        type: String
    }
}
</script>