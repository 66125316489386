<template>
    <div class="relative min-h-screen md:flex">

        <!-- Mobile Menu -->
        <div class="bg-gray-800 text-gray-100 flex justify-between md:hidden">
            <!-- Logo -->
            <a href="#" class="block p-4 text-blue-500 font-bold">Megaluzz</a>

            <!-- Mobile menu button -->
            <button id="mobile-menu-button" class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
        </div>

        <!-- sidebar -->
        <div id="sidebar" class="sidebar bg-white text-gray-500 w-64 space-y-6 py-4 px-2 absolute inset-y-0 left-0 transsegments -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">
            <!-- logo -->
            <a href="" class="text-white flex flex-col items-center space-x-2 px-4">
                <img class="w-32" src="../assets/logo-megaluzz.png" alt="Megaluzz Logo">
            </a>

            <!-- <div class="border-b border-1 border-gray-200"></div> -->
            <!-- nav -->
            <nav class="py-6">
                <router-link :to="{ name: 'user' }" class="menu-sidebar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" class="menu-sidebar-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                    </svg>
                    <span class="menu-sidebar-label">Meu Perfil</span>
                </router-link>
                <router-link :to="{ name: 'team' }" class="menu-sidebar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" class="menu-sidebar-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                    </svg>
                    <span class="menu-sidebar-label">Fazer um pedido</span>
                </router-link>
                <router-link :to="{ name: 'requests' }" class="menu-sidebar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" class="menu-sidebar-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                    </svg>
                    <span class="menu-sidebar-label">Pedidos realizados</span>
                </router-link>
                <router-link :to="{ name: 'jobs' }" class="menu-sidebar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" class="menu-sidebar-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                    <span class="menu-sidebar-label">Processos Seletivos</span>
                </router-link>
                <router-link :to="{ name: 'notes' }" class="menu-sidebar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" class="menu-sidebar-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                    <span class="menu-sidebar-label">Reuniões</span>
                </router-link>
                <router-link :to="{ name: 'reports' }" class="menu-sidebar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" class="menu-sidebar-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                    </svg>
                    <span class="menu-sidebar-label">Exportar Relatório</span>
                </router-link>
                <router-link :to="{ name: 'megafiles' }" class="menu-sidebar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" class="menu-sidebar-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    <span class="menu-sidebar-label">Mega Arquivos</span>
                </router-link>
            </nav>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import AuthService from '../services/AuthService';

export default {
    name: 'Sidebar',
    setup() {
        const isAdmin = ref(false);

        onMounted(async () => {
            const button = document.getElementById('mobile-menu-button');
            const sidebar = document.getElementById('sidebar');

            const { is_admin } = await AuthService.getAuthInfo();
            isAdmin.value = is_admin;

            if (button) {
                button.addEventListener('click', () => {
                    sidebar.classList.toggle('-translate-x-full');
                });
            }
        });

        return { isAdmin }
    },
}
</script>
