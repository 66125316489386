<template>
    <div class="mb-8">
        <div class="flex space-x-2">
            <slot name="icon"></slot>
            <h1 class="page-title">{{ title }}</h1>
        </div>
        <hr class="page-title-separator">
    </div>
</template>

<script>

export default {
    props: {
        title: String
    }
}
</script>
