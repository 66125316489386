<template>
    <div class="text-center bg-green-100 p-10" v-if="show">
        <h3 class="text-md text-green-600 font-semibold">{{ text }}</h3>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
        text: String,
        show: Boolean
    }
}
</script>