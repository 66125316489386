<template>
    <router-link :to="to" v-if="to">
        <button class="btn btn-primary-outline" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="ml-2">Voltar</span>
        </button>
    </router-link>
</template>

<script>
export default {
    name: 'BackButton',
    props: {
        to: [String, Object]
    }
}
</script>