<template>
    <div>
        <img class="object-fill rounded-full bg-blue-100" :class="{ 'w-10 h-10': size === 'small', 'w-14 h-14': !size, 'w-20 h-20': size === 'large', 'w-28 h-28': size === 'extra-large' }" :src="path" v-if="path" />
        <div class="rounded-full bg-blue-100" :class="{ 'w-10 h-10': size === 'small', 'w-14 h-14': !size, 'w-20 h-20': size === 'large', 'w-28 h-28': size === 'extra-large' }" v-if="!path"></div>
    </div>
</template>

<script>
export default {
    props: {
        path: String,
        size: String
    }
}
</script>