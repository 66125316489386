import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Requests from '../views/Requests.vue';
import Jobs from '../views/Jobs';
import Job from '../views/Job';
import Team from '../views/Team';
import Reports from '../views/Reports';
import AuthService from '../services/AuthService';
import User from '../views/User';
import MeetingNotes from '../views/MeetingNotes.vue';
import Request from '../views/Request.vue';
import RequestForm from '../views/RequestForm.vue';
import MegaFiles from '../views/MegaFiles.vue';

const routes = [
  {
    path: '/',
    name: 'auth',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/solicitacoes',
    name: 'requests',
    component: Requests
  },
  {
    path: '/solicitacao/:id',
    name: 'request',
    component: Request
  },
  {
    path: '/nova-solicitacao/:id_admin/:id_request?',
    name: 'requestform',
    component: RequestForm
  },
  {
    path: '/processos-seletivos',
    name: 'jobs',
    component: Jobs
  },
  {
    path: '/mega-arquivos',
    name: 'megafiles',
    component: MegaFiles
  },
  {
    path: '/processo-seletivo/:id?',
    name: 'job',
    component: Job
  },
  {
    path: '/notes',
    name: 'notes',
    component: MeetingNotes
  },
  {
    path: '/meu-perfil',
    name: 'user',
    component: User
  },
  {
    path: '/relatorios',
    name: 'reports',
    component: Reports
  },
  {
    path: '/time',
    name: 'team',
    component: Team
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !AuthService.isAuthenticated()) next({ name: 'login' })
  else next();
})

export default router
