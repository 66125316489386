<template>
	<div>
		<div class="flex mb-4">
			<label v-if="label" class="label">
				{{ label }}
				<BaseRequiredStar v-if="$attrs.required" />
			</label>
			<slot name="help"></slot>
		</div>
		<div :class="{ 'flex gap-x-4': !vertical, 'flex flex-col space-y-4': vertical }">
			<BaseRadio v-for="option in options" :key="option.uuid"
				:label="option.title"
				:value="option.value"
				:modelValue="modelValue"
				:name="name"
				@update:modelValue="$emit('update:modelValue', $event)"
			/>
		</div>
		<slot name="tip"></slot>
	</div>
</template>

<script>
export default {
	props: {
		label: {
            type: String,
            default: ''
        },
		options: {
			type: Array,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		modelValue: {
			type: [String, Number],
			required: true
		},
		vertical: {
			type: Boolean,
			default: false
		}
	}
}
</script>