<template>
    <div v-if="help">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-300 ml-1 -mt-1 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" @mouseover="show" @mouseleave="hide">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
        </svg>
        <div v-if="showTip" class="absolute bg-white border-0 mb-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg" :id="id">
            <div>
                <div class="bg-white text-gray-600 p-3 mb-0 border rounded">
                    {{ help }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';

export default {
    props: {
        help: String,
        id: String
    },
    setup() {
        let showTip = ref(false);

        function show() {
            showTip.value = true;
        }

        function hide(elemntId) {
            showTip.value = false;
        }

        return {
            show,
            hide,
            showTip
        }
    }
}
</script>