<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="page-header-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
            </template>
        </PageHeader>

        <div class="flex justify-center" v-if="loading">
            <Loading :show="true" text="Carregando dados, aguarde..." />
        </div>

        <div class="grid grid-cols-4 gap-x-4 gap-y-4" v-if="!loading">
            <div v-for="profile in admins" :key="profile.id">
                <ProfileCard :admin="profile" />
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import ProfileCard from '@/components/ProfileCard';
import { UserAdminService } from '../services/Entity';
import Swal from 'sweetalert';

export default {
    components: {
        ProfileCard
    },
    setup() {
        const pageHeader = ref('Para quem será o pedido?');
        const loading = ref(false);
        const admins = ref([]);

        async function init() {
            await loadTeam();
        }

        async function loadTeam() {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                loading.value = true;
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit, { status: true });

                admins.value = items;
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao listar o time',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        init();

        return {
            pageHeader,
            loading,
            admins
        }
    },
}
</script>